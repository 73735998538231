.MuiFormControl-root {
  margin-bottom: 12px;
  border-radius: 12px !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.MuiDataGrid-row {
  cursor: pointer !important;
}

/***************** form styles override /*****************/

.MuiFormControl-root {
  margin-bottom: 12px !important;
}
.MuiInputBase-root {
  border-radius: 10px;
}
.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #0a3a67 !important;
  opacity: 0.8 !important;
}
.MuiFormLabel-root {
  border-radius: 5px !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  padding-bottom: 10px !important;
  margin-top: 4px !important;
  color: #0a3a67 !important;
}
.MuiOutlinedInput-root {
  border-radius: 12px;
}
.MuiOutlinedInput-input {
  font-size: '16px' !important;
  font-family: 'Lato', sans-serif !important;
  color: #0a3a67 !important;
  font-weight: 500 !important;
  border-radius: 12px !important;
}
.MuiFormHelperText-root {
  color: '#bf3333' !important;
}
.MuiMenuItem-root {
  color: '#0A3A67' !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: 500 !important;
  font-size: 15px !important;
}
.MuiTypography-root {
  font-family: 'Lato', sans-serif !important;
}
.MuiChip-root {
  margin-right: 8px !important;
  background-color: #fff !important;
  border: none !important;
  font-family: 'Lato', sans-serif !important;
  font-size: 12px !important;
  color: #0a3a67 !important;
  font-weight: 500 !important;
}
.MuiClock-root {
  margin: 0 !important;
}
.MuiClockPicker-root {
  width: 100% !important;
  max-height: 100% !important;
}
.MuiClock-clock {
  background-color: #fff !important;
}
.react-calendar__navigation {
  height: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}
